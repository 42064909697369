// Icon.js
import React from 'react';

export function BrandicoLinkedinRect(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1000 1000" {...props}>
      <path fill="currentColor" d="M196.064.25C88.347.25.187 88.408.187 196.127v607.841c0 107.717 88.158 195.845 195.877 195.845h607.841c107.718 0 195.845-88.127 195.845-195.845V196.127C999.75 88.41 911.623.25 803.905.25H196.064zm49.266 164.948c51.648 0 83.461 33.906 84.443 78.475c0 43.585-32.797 78.444-85.442 78.444h-.969c-50.665 0-83.412-34.857-83.412-78.444c0-44.568 33.738-78.475 85.379-78.475zm445.08 208.31c99.329 0 173.79 64.922 173.79 204.436v260.449H713.247V595.406c0-61.06-21.847-102.718-76.476-102.718c-41.704 0-66.562 28.078-77.476 55.202c-3.987 9.704-4.967 23.257-4.967 36.832v253.671H403.375s1.981-411.613 0-454.233h150.984v64.324c20.06-30.95 55.942-74.977 136.051-74.977zm-521.556 10.685h150.953v454.202H168.854V384.193z"></path>
    </svg>
  );
}




export function MaterialSymbolsLightStackedEmail(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M3.615 19.77q-.69 0-1.153-.463Q2 18.844 2 18.154v-9.5h1v9.5q0 .27.173.442t.442.173h14.77v1H3.615Zm3-3q-.69 0-1.153-.463Q5 15.844 5 15.154v-9.77q0-.69.463-1.152t1.152-.463h13.77q.69 0 1.152.463q.463.462.463 1.153v9.769q0 .69-.463 1.153q-.462.462-1.152.462H6.615Zm6.885-5.24L21 5.945q0-.292-.048-.558q-.048-.267-.144-.501L13.5 10.312L6.154 4.923q-.096.235-.125.491T6 5.944l7.5 5.587Z"></path></svg>
    )
  }