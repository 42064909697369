import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import './style/BlogPost.css';

const BlogPost = () => {
  const [content, setContent] = useState('');
  const { postId } = useParams();
  const location = useLocation();

  // Define searchParams right after location
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get('title');
  const date = searchParams.get('date');
  const imageUrl = searchParams.get('imageUrl');

  useEffect(() => {
    fetch(`/blog-posts/${postId}.txt`)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((err) => console.error('Failed to fetch blog content', err));
  }, [postId]);

  return (
    <div className="blog-post-page">
      <Link to="/blog">Back to Blog List</Link>
      <header className="blog-post-header">
        <h1 className="blog-post-title">{title}</h1>
        <p className="blog-post-date">{date}</p>
      </header>
      <article className="blog-post-content">
        {imageUrl && <img src={imageUrl} alt={title} className="blog-post-image" />}
        {content}
      </article>
    </div>
  );
};

export default BlogPost;
