import React from 'react';
import './style/About.css';
import picture from '../images/headshot.png';
import linkedinIcon from '../images/linkedinblack.png'; // Your LinkedIn icon
import mailIcon from '../images/emailblack.jpg'; // Your email icon
import useWindowDimensions from '../windowDimensions';
import { BrandicoLinkedinRect } from './icon';
import { MaterialSymbolsLightStackedEmail } from './icon';


function ProfileImage() {
    const { width } = useWindowDimensions();
    const isMobile = width <= 1064;
    return (
        <div className={`general-container ${isMobile ? "about-container-mobile" : "about-container"}`} data-aos="fade-right">
            <img className={isMobile ? "profile-image-mobile" : "profile-image"} src={picture} alt={isMobile ? "mobile profile" : "profile"}/>
        </div>
    );
}

function About() {
    const { width } = useWindowDimensions();
    const isMobile = width <= 1064;
    return (
        <div id="about" className={`about ${isMobile ? "mobile-about" : ""}`}>
            <ProfileImage />
            <div className={isMobile ? "about-text-mobile" : "about-text"} data-aos="fade-left">
                <div className="subheading-text">About Me</div>
                <div className="description-text" style={{ paddingTop: '20px' }}>I have experience at Amazon developing software in their payment logistics, inventory tracking, and even in their game tech division, two of the stints being full-stack and one completely backend. <br></br><br></br> Check in later for a sneak peak of what I've been up to</div>
                <div className="about-text-element">
                    <a href="https://www.linkedin.com/in/johann-ramirez">
                        <BrandicoLinkedinRect className="social-icon" />
                    </a>
                    <a href="mailto:johann@clipsai.com">
                        <MaterialSymbolsLightStackedEmail className="social-icon" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default About;
