// Blog.js
import React from 'react';
import './style/Blog.css';
import { Link, useNavigate } from 'react-router-dom';
import image1 from '../images/flower.png';
import image2 from '../images/shoes.png';

const articles = [
  {
    id: "1",
    title: "Life and Internships",
    category: "Work",
    date: "November 18 2023",
    summary: "Messy Reflections on Life and Internships...",
    imageUrl: image1
  },
  {
    id: "2",
    title: "Oddities and Disparties",
    category: "University",
    date: "November 19 2023",
    summary: "UT Austin's little known park...",
    imageUrl: image2
  },
];


const Blog = () => {
  const navigate = useNavigate();
  const goBack = () => navigate('/');

  return (
    <div className="blog-page">
      <button onClick={goBack} className="back-button">Back Home</button>
      <header className="blog-header">
        <h1>Johann's Brain</h1>
        <p className="blog-subtext">The opinions expressed here are my own and do not necessarily represent those of current or past employers.</p>
      </header>
      <section className="blog-list">
        {articles.map((article) => (
          <Link to={`/blog/${article.id}?title=${encodeURIComponent(article.title)}&date=${encodeURIComponent(article.date)}&imageUrl=${encodeURIComponent(article.imageUrl)}`} className="blog-card-link">
            <article key={article.id} className="blog-card">
              <div className="blog-image-container">
                {article.imageUrl && <img src={article.imageUrl} alt={article.title} className="blog-image" />}
              </div>
              <div className="blog-text">
                <h2>{article.title}</h2>
                <p className="blog-date">{article.date}</p>
                <p className="blog-summary">{article.summary}</p>
              </div>
            </article>
          </Link>
        ))}
      </section>
    </div>
  );
};

export default Blog;
