import './App.css';
import { Navbar } from './components/Navbar1';
// import Introduction from './components/Introduction'
import {EducationBanner, SkillsBanner } from './components/Banner'
import About from './components/About'
// import Projects from './components/Projects'
import Experience from './components/Experience'
// import BottomNavbar from './components/BottomNavbar'

function Home() {
    return (
        <div>
            <Navbar />
            {/* <Introduction /> */}
            <About />
            {/* <Projects /> */}
            <Experience />
            <div className="heading-text" data-aos="fade-up">Current Toolbox and Past Courses</div>
            <SkillsBanner />
            <EducationBanner />
            {/* <BottomNavbar /> */}
        </div>
    );
}

export default Home;