import './App.css';
import * as React from 'react';
import { Routes, Route} from "react-router-dom";
import Home from './Home.js'
// import logo from './logo.svg';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// import ResponsiveAppBar from './components/NavBar.js'
// import useWindowDimensions from './windowDimensions';
import Box from '@mui/material/Box';
import Blog from './components/Blog'; // Make sure the path matches where your Blog component is located
import BlogPost from './components/BlogPost'; // Make sure the path matches where your BlogPost component is located



function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center" size="xxl" fontWeight="1000" letterSpacing=".1rem" sx={{ my: 3 }}>
      {'Copyright © '}
      <Link color="inherit">
        JohannRamirez
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function App() {
  // const { height, width } = useWindowDimensions();
  return (
    // <Container maxWidth="xl">
    //    <Box sx={{ my: 6 }}>
    //      <Typography variant="h3" component="h1" gutterBottom align="center" fontWeight="1000" letterSpacing=".3rem" color="inherit" textDecoration="none" color="white" >
    //        Johann Ramirez
    //      </Typography>
    //      {/* <ResponsiveAppBar /> */}
    //      <div>
    //      <Routes>
    //       <Route path="/" element={<Home />} />
    //      </Routes>
    //      </div>
    //      {/* <Home /> */}
    //      {/* <About /> */}
    //      {/* <AboutMe /> */}
    //    </Box>
    //  </Container>
    <div>
         <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} /> {/* Add this line for blog routing */}
          <Route path="/blog/:postId" element={<BlogPost />} />
         </Routes>
         {/*  copywrite */}
         {/* <Copyright/> */}

    </div>
  );
}

export default App;