import React, { useState } from 'react';
import './style/Banner.css';
import Line from './Line';
import c_image from '../images/c.svg';
import css_image from '../images/css.svg';
import docker_image from '../images/docker.svg';
import git_image from '../images/git.svg';
import github_image from '../images/github.svg';
import html5_image from '../images/html5.svg';
import java_image from '../images/java.svg';
import js_image from '../images/javascript.svg';
import mysql_image from '../images/mysql.svg';
import python_image from '../images/python.svg';
import react_image from '../images/react.svg';

function Tag({ text, img_src, handleMouseOver, handleMouseOut, hovered }) {
  const style = {
    padding: '10px 20px',
    margin: '5px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '25px',
    backgroundColor: hovered ? 'lightblue' : '#f0f0f0', // Blue background when hovered
    color: hovered ? 'white' : 'black', // White text when hovered
    transition: 'background-color 0.3s, color 0.3s',
    cursor: 'pointer',
  };

  const imgStyle = {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  };

  return (
    <div className="tag" style={style} onMouseOver={() => handleMouseOver(text)} onMouseOut={handleMouseOut}>
      {img_src && <img src={img_src} alt={text} style={imgStyle} />}
      <span>{text}</span>
    </div>
  );
}

function SkillsBanner() {
  const [hoveredTag, setHoveredTag] = useState(null);

  const skills = [
    { text: 'C++', img_src: c_image },
    { text: 'CSS', img_src: css_image },
    { text: 'Docker', img_src: docker_image },
    { text: 'Git', img_src: git_image },
    { text: 'GitHub', img_src: github_image },
    { text: 'HTML5', img_src: html5_image },
    { text: 'Java', img_src: java_image },
    { text: 'JavaScript', img_src: js_image },
    { text: 'MySQL', img_src: mysql_image },
    { text: 'Python', img_src: python_image },
    { text: 'React', img_src: react_image },
    // ... (include all other skills with their respective images)
  ];

  return (
    <div>
      <Line />
      <div className="tag-cloud">
        {skills.map((skill) => (
          <Tag
            key={skill.text}
            text={skill.text}
            img_src={skill.img_src}
            handleMouseOver={setHoveredTag}
            handleMouseOut={() => setHoveredTag(null)}
            hovered={hoveredTag === skill.text}
          />
        ))}
      </div>
      <Line />
    </div>
  );
}

function EducationBanner() {
  const [hoveredTag, setHoveredTag] = useState(null);

  const educationItems = [
    'Data Structures',
    'Computer Architecture',
    'Operating Systems',
    'Algorithms',
    'Modern Web Applications',
    'Integrated Design Thinking',
    'Principles of Machine Learning',
    'Object Oriented Programming',
    'Discrete Math',
    'Competitive Programming',
    'Software Engineering',
    'Natural Language Processing',
    'Linear Algebra',
    'Natural Language Processing',
    // ... other educational items
  ];

  return (
    <div>
      <Line />
      <div className="tag-cloud">
        {educationItems.map((item) => (
          <Tag
            key={item}
            text={item}
            handleMouseOver={setHoveredTag}
            handleMouseOut={() => setHoveredTag(null)}
            hovered={hoveredTag === item}
          />
        ))}
      </div>
      <Line />
    </div>
  );
}

export { SkillsBanner, EducationBanner };
