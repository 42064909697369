import React from 'react';
import './style/Experience.css';
import ut_logo from '../images/ut_logo.png';
import cai_logo from '../images/cai.png';
import amazon_logo from '../images/amazon_logo.jpeg';
import aws_logo from '../images/aws.png';
import white_sands_logo from '../images/whitesands.png';
import { EducationBanner } from "./Banner";
import useWindowDimensions from '../windowDimensions';

function Education() {
    return (
        <div className="education" data-aos="fade-up">
            <img className="ut-logo" src={ut_logo} alt="UT Logo" />
            <div className="education-info">
                <div className="description-text education-text">University of Texas at Austin</div>
                <div className="description-text education-text">B.S. Computer Science & Certificate in Smart Cities</div>
                <div className="description-text education-text">Graduating May 2024</div>
            </div>
        </div>
    );
}
function EducationMobile() {
    return (
        <div>
            <div className="education-mobile" data-aos="fade-up">
                <div className="general-container">
                    <img className="ut-logo" src={ut_logo} alt="UT Logo"/>
                </div>
                <div className="description-text education-text-mobile" style={{ marginTop: '10px' }}>University of Texas at Austin<br></br><br></br></div>
                <div className="description-text education-text-mobile">B.S. Computer Science && Certificate in Smart Cities<br></br><br></br></div>
                <div className="description-text education-textmobile">Graduating May 2024<br></br></div>
            </div>
            <EducationBanner />
        </div>
    )
}
function ExperienceElement(props) {
    return (
        <div className="experience-element" data-aos="fade-left">
            <img className="experience-logo" src={props.logo} alt="Experience Logo"/>
            <div className="experience-info">
                <div className="subheading-text">{props.name}</div>
                <div className="description-text">{props.role}</div>
                <div className="description-text">{props.description}</div>
            </div>
        </div>
    );
}

function ExperienceElementMobile(props) {
    return (
        <div className="general-container experience-element-mobile" data-aos="fade-left">
            <div className="mobile-row">
                <img className="experience-logo" src={props.logo} alt="Experience Logo"/>
                <div>
                    <div className="small-subheading-text">{props.name}</div>
                    <div className="small-description-text">{props.role}</div>
                </div>
            </div>
            <div className="small-description-text" style={{ "paddingLeft": "20px" }}>{props.description}</div>
        </div>
    )
}
// function SubExperienceElement(props) {
//     return (
//         <div className="general-container sub-experience-element">
//             <img className="sub-experience-logo" src={props.logo} />
//             <div>
//                 <div className="small-subheading-text">{props.name}</div>
//                 <div className="small-description-text">{props.description}</div>
//             </div>
//         </div>
//     )
// }
function Timeline() {
    return (
        <div className="timeline">
            <div className="timeline-circle"></div>
            <div className="timeline-subline">&nbsp;</div>
            <div className="timeline-circle"></div>
            <div className="timeline-subline">&nbsp;</div>
            <div className="timeline-circle"></div>
            <div className="timeline-subline">&nbsp;</div>
            <div className="timeline-circle"></div>
        </div>
    )
}
function AllExperience() {
    return (
        <div className="all-experience">
            <ExperienceElement logo={white_sands_logo} name="White Sands Missile Range" role="Cybersecurity Intern" />
            <ExperienceElement logo={amazon_logo} name="Amazon Marketplace Org" role="Software Development Engineer" />
            <ExperienceElement logo={amazon_logo} name="Amazon Consumer Org" role="Software Development Engineer" />
            <ExperienceElement logo={aws_logo} name="Amazon Game Tech Org" role="Software and Graphics Engineer" />
            <ExperienceElement logo={cai_logo} name="Clips AI" role="Founding Engineer" />
        </div>
    );
}
function AllExperienceMobile() {
    return (    
        <div>
            {/* <ExperienceElementMobile logo={amazon_logo} name="Amazon Consumer Org" role="Software Development Engineer" description="Developed a payment disbursement validation system that decreased overhead by 63% when checking if a seller on the marketplace is due for a payment. " /> */}
            <ExperienceElementMobile logo={white_sands_logo} name="White Sands Missile Range" role="Cybersecurity Intern" description="" />
            <ExperienceElementMobile logo={amazon_logo} name="Amazon Consumer" role="Software Development Engineer" description="" />
            <ExperienceElementMobile logo={amazon_logo} name="Amazon Inventory Management" role="Software Development Engineer" description="" />
            <ExperienceElementMobile logo={cai_logo} name="Clips AI" role="Software Development Manager" description="" />
        </div>
    );
}
function Experience() {
    const { width } = useWindowDimensions();

    const educationComponent = width > 1064 ? <Education /> : <EducationMobile />;
    const experienceComponent = width > 1064 ? <AllExperience /> : <AllExperienceMobile />;

    return (
        <div id="experience" className="experience">
            <div className="heading-text" data-aos="fade-up">Education and Experience</div>
            <div className="education-experience-container">
                {educationComponent}
                <div className="experience-container">
                    <Timeline />
                    {experienceComponent}
                </div>
            </div>
        </div>
    );
}

export default Experience;