import React from 'react';
import './style/Navbar.css';
import logo from '../images/logo.png';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <div className="navbar-container" data-aos="fade-down">
            <div className="navbar">
                <HashLink to="#introduction"><img className='logo' src={logo} alt="logo"/></HashLink>
                <div className="navbar-buttons">
                    {/* <HashLink to="#about"><div className='navbar-button'>About</div></HashLink>
                    <HashLink to="#experience"><div className='navbar-button'>Experience</div></HashLink>
                    <a href="resume_link"><div className='navbar-button'>Resume</div></a> */}
                    {/* <a href="github_profile_link"><div className='navbar-button'>Github</div></a> */}
                    <Link to="/blog"><div className='navbar-button'>Blog</div></Link>
                </div>
            </div>
        </div>
    );
}

export { Navbar };
